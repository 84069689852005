import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const StyledDiv = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
}));

const PostalAddress = () => {
  return (
    <Box pt={1}>
      <StyledDiv>
        <Typography variant='subtitle1'>Magic Audio LLC</Typography>
        <Typography variant='subtitle2'>
          1309 Coffeen Avenue, STE 1200
        </Typography>
        <Typography variant='subtitle2'>Sheridan, WY, 82801</Typography>
        <Typography variant='subtitle2'>USA</Typography>
      </StyledDiv>
    </Box>
  );
};

export default PostalAddress;
