import React from 'react';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Link from '../../../components/Link';
import WebsiteMenu from '../../../../domain/menu/WebsiteMenu';
import translations from '../../../../locales-js/en/translations';

const Item = styled('div')(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  // ml: 1,
  textAlign: 'left',
  color: theme.palette.text.secondary,
  a: {
    textDecoration: `none`,
    width: '100%',
    color: theme.palette.text.secondary,
    display: 'inline-block',
  },
  'a:visited': { textDecoration: `none` },
  // 'a:hover': {
  //   textDecoration: `underline`,
  // },
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? '#1D1E1F' : '#fff',
    // textDecoration: `underline`,
  },
}));

interface FooterMenuProps {
  menu: WebsiteMenu;
}

const FooterMenu = ({ menu }: FooterMenuProps) => {
  return (
    <>
      <Typography variant='body1' align='left' mt={1} mb={1}>
        {menu.title}
      </Typography>
      <Stack>
        {menu.menuItems.map((item) => {
          return item.title === translations.menuDivider ? (
            <Divider key={item.title + item.url} />
          ) : (
            <Item key={item.title + item.url}>
              <Link to={item.url} key={item.title + item.url}>
                {item.title}
              </Link>
            </Item>
          );
        })}
      </Stack>
    </>
  );
};

export default FooterMenu;
