import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Copyright = () => {
  return (
    <Box mt={3}>
      <Typography variant='body2' align='center' gutterBottom={false}>
        © {new Date().getFullYear()} Magic Audio LLC. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Copyright;
