const translations = {
  Home: 'Home',
  Contact: 'Contact Us',
  About: 'About Us',
  More: 'More',
  comingSoon: 'Coming Soon',
  menuDivider: 'divider',
  product: 'Product',
  products: 'Products',
  productHome: 'Home',
  productLiveDemo: 'Real-time demo',
  productExtension: 'Web extension / addon',
  productRoadmap: 'Roadmap',
  plansAndPricing: 'Plans and pricing',
  company: 'Company',
  companyAbout: 'About',
  companyContact: 'Contact',
  companyBackground: 'Background',
  legal: 'Legal',
  legalCookiePolicy: 'Cookie Policy',
  legalPrivacyPolicy: 'Privacy Policy',
  legalTermsOfService: 'Terms and Conditions',
  legalUserDataDeletion: 'User Data Deletion',
  legalDisclaimer: 'Disclaimer',
  legalCCPA: 'Do Not Sell My Personal Information',
  connect: 'Connect',
  connectFacebook: 'Facebook',
  connectInstagram: 'Instagram',
  connectLinkedIn: 'Linked In',
  connectYouTube: 'YouTube',
};

export default translations;
