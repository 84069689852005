import React, { useContext } from 'react';

import AnonymousUserMenu from './AnonymousUserMenu';
import EponymousUserMenu from './EponymousUserMenu';

import { RootContext } from '../../../../../app/contexts/RootContextProvider';

const UserMenu = () => {
  const { currentUser } = useContext(RootContext);

  return typeof currentUser === `undefined` ? (
    <></>
  ) : currentUser ? (
    <EponymousUserMenu />
  ) : (
    <AnonymousUserMenu />
  );
};

export default UserMenu;
