import WebsiteMenu from '../../../../domain/menu/WebsiteMenu';
import WebsiteMenuItem from '../../../../domain/menu/WebsiteMenuItem';

import translations from '../../../../locales-js/en/translations';

const useFooterMenus = () => {
  const productMenu = new WebsiteMenu(translations.product, [
    new WebsiteMenuItem(translations.productExtension, '/web-extension-addon'),

    new WebsiteMenuItem(
        translations.menuDivider,
        translations.menuDivider + '1'
    ),

    new WebsiteMenuItem(
        translations.productLiveDemo,
        '/web-extension-addon/real-time-demo'
    ),

    new WebsiteMenuItem(
      translations.menuDivider,
      translations.menuDivider + '2'
    ),

    new WebsiteMenuItem(translations.plansAndPricing, '/plans-and-pricing'),

    // new WebsiteMenuItem(
    //   translations.menuDivider,
    //   translations.menuDivider + '3'
    // ),

    // new WebsiteMenuItem(
    //   translations.productRoadmap,
    //   '/web-extension-addon/roadmap'
    // ),
  ]);

  const companyMenu = new WebsiteMenu(translations.company, [
    new WebsiteMenuItem(translations.companyAbout, '/company/about'),
    new WebsiteMenuItem(translations.companyContact, '/company/contact'),
  ]);

  const legalMenu = new WebsiteMenu(translations.legal, [
    new WebsiteMenuItem(
      translations.legalPrivacyPolicy,
      '/legal/privacy-policy'
    ),
    new WebsiteMenuItem(translations.legalCookiePolicy, '/legal/cookie-policy'),
    new WebsiteMenuItem(
      translations.legalTermsOfService,
      '/legal/terms-and-conditions'
    ),
    // new WebsiteMenuItem(
    //   translations.legalUserDataDeletion,
    //   '/legal/user-data-deletion'
    // ),
    new WebsiteMenuItem(translations.legalDisclaimer, '/legal/disclaimer'),
    // new WebsiteMenuItem(
    //   translations.legalCCPA,
    //   'https://app.termly.io/notify/83b770c6-14aa-47b1-8ed7-0f8a0c4e739c'
    // ),
  ]);

  const connectMenu = new WebsiteMenu(translations.connect, [
    new WebsiteMenuItem(
      translations.connectInstagram,
      'https://www.instagram.com/magicaudiollc/'
    ),
    new WebsiteMenuItem(
      translations.connectFacebook,
      'https://www.facebook.com/MagicAudioLLC'
    ),
    new WebsiteMenuItem(
      translations.connectLinkedIn,
      'https://www.linkedin.com/company/magic-audio-llc'
    ),
    new WebsiteMenuItem(
      translations.connectYouTube,
      'https://www.youtube.com/channel/UCEKwonmd0DZoSmp7SYS1U1w/playlists'
    ),
  ]);

  return {
    productMenu,
    companyMenu,
    legalMenu,
    connectMenu,
  };
};

export default useFooterMenus;
