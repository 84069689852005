import React from 'react';
import { navigate } from 'gatsby';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import PersonIcon from '@mui/icons-material/Person';
import { grey } from '@mui/material/colors';

const AnonymousUserMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignIn = () => {
    setAnchorEl(null);
    navigate(`/account/sign-in`);
  };

  return (
    <>
      <Avatar onClick={handleClick} sx={{ bgcolor: grey[300] }}>
        <PersonIcon />
      </Avatar>
      <Menu
        id='fade-menu'
        MenuListProps={{
          'aria-labelledby': 'anonymous-user-menu',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleSignIn}>Sign In</MenuItem>
      </Menu>
    </>
  );
};

export default AnonymousUserMenu;
