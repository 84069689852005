import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import translations from '../../../../locales-js/en/translations';

const DoNotSellMyPersonalInformationButton = () => {
  const handleDoNotSellMyPersonalInformation = () => {
    window.open(
      `https://app.termly.io/notify/83b770c6-14aa-47b1-8ed7-0f8a0c4e739c`
    );
  };

  return (
    <Box mt={0}>
      <Button
        variant='text'
        size='small'
        onClick={handleDoNotSellMyPersonalInformation}
        color='inherit'
        sx={{
          fontSize: '0.61rem',
        }}
      >
        {translations.legalCCPA}
      </Button>
    </Box>
  );
};

export default DoNotSellMyPersonalInformationButton;
