import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { grey } from '@mui/material/colors';

import { RootContext } from '../../../../../../app/contexts/RootContextProvider';
import useUserInfo from '../../../../../../hooks/useUserInfo';
import firebase from '../../../../../../FirebaseConfig';

const EponymousUserMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { currentUser } = useContext(RootContext);
  const { getUserDisplayName } = useUserInfo(currentUser);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = (event) => {
    event.preventDefault();
    setAnchorEl(null);
    navigate(`/`);
    firebase.auth().signOut();
    // window.location.reload();
  };

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  };

  return (
    <>
      {/* {getUserPhotoURL() && (
        <Avatar
          src={getUserPhotoURL()}
          onClick={handleClick}
          // sx={{ bgcolor: grey[300] }}
        />
      )}
      {!getUserPhotoURL() && (
        <Avatar
          {...stringAvatar(getUserDisplayName())}
          onClick={handleClick}
          // sx={{ bgcolor: grey[300] }}
        />
      )} */}

      <Avatar
        {...stringAvatar(getUserDisplayName())}
        onClick={handleClick}
        sx={{ bgcolor: grey[300] }}
      />

      <Menu
        id='fade-menu'
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
      </Menu>
    </>
  );
};

export default EponymousUserMenu;
