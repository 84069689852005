import React from 'react';
import { AppBar, Box, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import NavigationMenu from './NavigationMenu';
import UserMenu from './UserMenu';

import Link from '../../../components/Link';

// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Header = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    // target: props.window ? window() : undefined,
  });

  // console.log(`trigger`, trigger);

  const Item = styled('div')(({ theme }) => ({
    ...theme.typography.h4,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    a: {
      textDecoration: `none`,
      fontFamily: 'Megrim',
      fontSize: '2.55rem',
      '@media screen and (max-width: 40em)': {
        fontSize: '1.95rem',
      },
      fontWeight: 'bold',
      color: trigger ? `white` : theme.palette.text.secondary,
    },
    'a:visited': { textDecoration: `none` },
  }));

  return (
    <AppBar
      position='fixed'
      sx={{
        background: trigger ? undefined : `transparent`,
        transition: trigger ? '0.3s' : '0.5s',
      }}
    >
      <Toolbar>
        <NavigationMenu />
        <Box sx={{ flexGrow: 1 }} />
        <Item>
          <Link to='/'>Magic Audio</Link>
        </Item>
        <Box sx={{ flexGrow: 1 }} />
        <UserMenu />
        {/* <Box
          sx={{
            position: 'absolute',
            left: '0',
            right: '0',
            margin: 'auto',
          }}
        >
          <Item>
            <Link to='/'>Magic Audio</Link>
          </Item>
        </Box> */}

        {/* <Link to='/'>
          <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
            MAGIC.AUDIO
          </Typography>
        </Link>
        <Link to='/'>
          <Button color='inherit'>Home</Button>
        </Link>
        <Link to='/demo'>
          <Button color='inherit'>Demo</Button>
        </Link>
        <Link to='/pricing'>
          <Button color='inherit'>Pricing</Button>
        </Link>
        <Link to='/account/sign-up'>
          <Button
            variant='contained'
            color='secondary'
            size='large'
            endIcon={<ArrowForwardIcon />}
            sx={{ ml: 5 }}
          >
            Sign Up
          </Button>
        </Link> */}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
