const useUserInfo = (currentUser) => {
  // console.log(`useUserInfo - currentUser:`, currentUser);

  const getUserDisplayName = () => {
    return currentUser ? currentUser.displayName : ``;
  };

  const getUserPhotoURL = () => {
    return currentUser ? currentUser.photoURL : ``;
  };
  return {
    getUserDisplayName,
    getUserPhotoURL,
  };
};

export default useUserInfo;
