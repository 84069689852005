import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
// import Typography from '@mui/material/Typography';

import Copyright from './Copyright';
import FooterMenu from './FooterMenu';

import useFooterMenus from './useFooterMenus';
import CookiePreferenceButton from './CookiePreferenceButton';
import DoNotSellMyPersonalInformationButton from './DoNotSellMyPersonalInformationButton';
import PostalAddress from './PostalAddress';

const Footer = () => {
  const { productMenu, companyMenu, legalMenu, connectMenu } = useFooterMenus();

  return (
    <Container>
      <Box
        sx={{
          borderTop: `1px solid #616263`,
        }}
        mt={0}
        pt={1}
      >
        <Container maxWidth='md'>
          <Grid
            container
            spacing={4}
            justifyContent='flex-start'
            alignItems='flex-start'
          >
            <Grid item xs={12} md={3}>
              <PostalAddress />
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid
                container
                spacing={1}
                justifyContent='flex-start'
                alignItems='flex-start'
              >
                <Grid item xs={6} md={3}>
                  <FooterMenu menu={productMenu} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <FooterMenu menu={companyMenu} />
                </Grid>
                <Grid item xs={6} md={2}>
                  <FooterMenu menu={connectMenu} />
                </Grid>
                <Grid item xs={6} md={4}>
                  <FooterMenu menu={legalMenu} />
                  <Divider />
                  <Box mt={1} />
                  <DoNotSellMyPersonalInformationButton />
                  <Box mt={1} />
                  <CookiePreferenceButton />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Footer;
