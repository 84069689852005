import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const StyledButton = styled('button')(({ theme }) => ({
  background: 'white',
  width: '165px',
  height: '30px',
  borderRadius: '3px',
  border: '1px solid #5f7d9c',
  fontFamily: 'Roboto, Arial',
  fontSize: '10px',
  color: '#5f7d9c',
  fontWeight: '600',
  boxSizing: 'border-box',
  cursor: 'pointer',
  padding: '0',
  outline: 'none',
}));

const CookiePreferenceButton = () => {
  const displayPreferenceModal = () => {
    if (typeof window[`displayPreferenceModal`] !== `undefined`) {
      return window[`displayPreferenceModal`]();
    }
  };

  return (
    <Box mt={0}>
      {/* <StyledButton
        className='termly-cookie-preference-button'
        type='button'
        onClick={displayPreferenceModal}
      >
        Manage Cookie Preferences
      </StyledButton> */}
      <Button
        className='termly-cookie-preference-button'
        variant='text'
        size='small'
        onClick={displayPreferenceModal}
        color='inherit'
        sx={{
          fontSize: '0.75rem',
        }}
      >
        Manage Cookie Preferences
      </Button>
    </Box>
  );
};

export default CookiePreferenceButton;
